import React, { useEffect, useState } from "react";
import { get } from "react-hook-form";
import { fetchGetJSON, regGet } from "../../api-requests/apiCallHelper";
import logo from "../Units/InfoSheetComponents/logo.png";
import { useHistory, useParams } from "react-router-dom";
import { Constants } from "../../api-requests/apiLinkConstants";
import { Divider } from "@mui/material";

const BookingAgreement = (props: any) => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const [booking, setBooking] = useState<any>(null);

  const callBooking = async () => {
    const potentialBooking = await fetchGetJSON(
      `${Constants.API_PATH_BOOKING_FULLINFO}${bookingId}`
    );
    console.log(potentialBooking);
    setBooking(potentialBooking.data);
  };
  useEffect(() => {
    console.log(`Props: ${bookingId}`);
    callBooking();
  }, []);

  return (
    <>
      <div className="bg-white">
        <div className="px-4 md:px-8 lg:px-24 xl:px-56">
          <div className="flex justify-between pt-5">
            <div className="justify-start">
              <h2 className="h2">Booking ID#{booking?.booking.id}</h2>
            </div>
            <div className="justify-end pt-4">
              <img className="h-3" src={logo} alt="" />
              <h2 className="h2">Booking Agreement Sheet</h2>
            </div>
          </div>
          <div className="line" />
        </div>
      </div>

      {/* Body Below: */}
      <div className="bg-white">
        <div className="space-y-5 px-4 md:px-8 lg:px-24 xl:px-56">
          <div>
            <div className="flex flex-col justify-between space-y-3">
              <h3 className="h3 mt-4">Booking Details</h3>
              <p className="p">First Name: {booking?.tenant.first_name}</p>
              <p className="p">Last Name: {booking?.tenant.last_name}</p>
              <p className="p">Check In: {booking?.booking.check_in}</p>
              <p className="p">Check Out: {booking?.booking.check_out}</p>
              <p className="p">Unit Address: {booking?.unit.location}</p>
              <p className="p">Unit Number: {booking?.unit.suite_number}</p>
              <Divider />
            </div>
          </div>
          <div className="space-y-3">
            <h3 className="h3">Tenant Signature</h3>
            <p className="p">
              <span className="signature-agreement text-4xl">
                {booking?.tenant.first_name} {booking?.tenant.last_name}{" "}
              </span>{" "}
              |{" "}
              {new Date(booking?.booking.created_at).toLocaleString("en-US", {
                timeZone: "America/New_York",
              })}
            </p>
            <p className="p">
              {booking?.tenant.first_name} {booking?.tenant.last_name}
            </p>
            <Divider />
          </div>
          <div className="space-y-3">
            <h3 className="h1">Terms of Service</h3>
            <p className="p">
              Greetings! Thank you for considering Sky View Suites (referred to
              as "Sky View Suites," "we," or "us"). Our website,
              www.torontofurnishedrentals.com, and related websites, networks,
              units, and services (collectively known as the “Service(s)”,
              “Website(s)”, and the “Unit(s)”), are provided by Sky View Suites.
              These terms of service referred to as the "Agreement," is a
              legally binding contract between “you” and “us” regarding the use
              of our Service. Take the time to carefully read the following
              terms and conditions. By clicking "I accept" or similar words,
              accessing and using the Service and Units, you agree that you have
              read, understood, and, as a condition of using the Service and
              Unit, agree to be bound by this Agreement. If you are not eligible
              or do not agree to be bound by this Agreement, you do not have the
              right to use the Service and Unit. Your use of the Service and
              Unit and our provision of the Service and license or permission to
              use the Unit (if applicable) constitutes an agreement between you
              and us to be bound by this Agreement. In certain cases, a separate
              master agreement may be signed under the discretion of Sky View
              Suites which may amend parts of this Agreement.
            </p>
            <h3 className="h3">Definitions</h3>
            <p className="p ml-5">
              <ul className="list-disc">
                <li>
                  "You"
                  <ul className="list-disc list-inside">
                    <li>
                      The individual moving in or someone securing the Unit on
                      behalf of the occupant
                    </li>
                  </ul>
                </li>
                <li>
                  “Us”/”We”
                  <ul className="list-disc list-inside">
                    <li>Sky View Suites Group Inc.</li>
                  </ul>
                </li>
                <li>
                  “Unit”
                  <ul className="list-disc list-inside">
                    <li>
                      The residential dwelling assigned to You that is
                      self-contained in a residentially zoned building.
                    </li>
                  </ul>
                </li>
                <li>
                  "Building"
                  <ul className="list-disc list-inside">
                    <li>
                      The residential building that the Unit is contained
                      within.
                    </li>
                  </ul>
                </li>
                <li>
                  “Property Management”
                  <ul className="list-disc list-inside">
                    <li>
                      Staff and Contractors that operate on behalf of the
                      Building.
                    </li>
                  </ul>
                </li>
                <li>
                  “Authorized Occupants”
                  <ul className="list-disc list-inside">
                    <li>
                      Those occupant names provided by You to Us in writing and
                      were approved to occupy the Unit by Us.
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
            <h3 className="h3">1. Eligibility</h3>
            <p className="p">
              To access the Service and Unit, you must be at least 18 years old.
              By accepting this Agreement, you confirm and guarantee to us that:
              (a) you meet the age requirement of 18 years or older, are an
              emancipated minor, or have the consent of a legal parent or
              guardian to agree to this Agreement; and (b) you possess the
              capability to enter into this Agreement; (c) you have not been
              previously suspended or removed from the Service or any of our
              Units; (d) your use of the Service and Units are, and will
              continue to be, in compliance with all applicable laws, rules, and
              regulations. If you are an organization (such as a company), the
              individual accepting this Agreement on your behalf must have the
              authority to bind you to this Agreement and you agree to be bound
              by the terms of this Agreement.
            </p>
            <h3 className="h3">2. Payments</h3>
            <p className="p ml-5">
              <ol className="list-decimal">
                <li>
                  <b>Method of Payment:</b> Fees must be paid through a credit
                  card. Sky View Suites reserves the right to allow Wire
                  Transfers, Electronic Funds Transfers, or certified cheques as
                  a method of payment, at its sole discretion and on a
                  case-by-case basis. This payment should include all applicable
                  taxes where required.
                </li>
                <li>
                  <b>Fees and Payment:</b> Certain features of the Service may
                  require payment of one-time and/or recurring fees. Before
                  payment, you will have the chance to review and accept the
                  fees that will be charged. All fees are non-refundable unless
                  stated otherwise in writing by us.
                </li>
                <li>
                  <b>Prices:</b> We have the right to set the pricing for the
                  Service and any products we sell, including but not limited to
                  On-Site Services or Units you may reserve or subscribe to
                  through the Service.
                </li>
                <li>
                  <b>Billing:</b> Accommodations will be billed each day, from
                  the check in date to the check out date inclusive.
                </li>
                <li>
                  <b>Authorization:</b> You authorize us to charge all
                  applicable sums, including but not limited to any security
                  deposits, reservation fees, extended stay fees, subscription
                  fees, Service fees, including On-Site Services, Damaged Unit
                  Fee, and any fees, costs, and penalties for overstaying your
                  reservation or subscription, along with all applicable taxes
                  where required, to the credit card on file, or by sending an
                  invoice.
                </li>
                <li>
                  <b>Interest and Late Charges: </b>If Sky View Suites do not
                  receive the payment within five (5) calendar days from the
                  payment due date or in certain cases the payment due date plus
                  the agreed net payment terms, you agree to pay an
                  administrative late charge of $50.00. Also, if the payment for
                  Guest Fees is not made when due, you agree to pay interest to
                  Sky View Suites at a rate of 12% per annum (1% per month). The
                  provision of payment of interest and late charges does not
                  imply a grace period.
                </li>
                <li>
                  <b>Failure to Vacate: </b>Failure to leave the unit by 11 am
                  on your check-out date will result in Sky View Suites charging
                  your credit card a $50 per hour fee for every hour past 11am
                  until 1pm. In addition to this fee, if there is an incoming
                  Guest that needs to be relocated, charges related to this
                  relocation will be made on your credit card on file. You are
                  also responsible for any additional losses suffered by us due
                  to your failure to vacate (i.e. inability to service a booking
                  due to your failure to vacate). Charges can be as high as
                  $1,000. The Guest shall also be responsible for any additional
                  losses suffered by Sky View Suites Group Inc. due to his/her
                  failure to vacate.
                </li>
              </ol>
            </p>
            <h3 className="h3">3. Cancellation Policy</h3>
            <p className="p ml-5">
              <ol className="list-decimal">
                <li>
                  <b>Full Refund:</b> Cancellation within 24 hours of confirming
                  the booking unless booked within 48 hours of check in.
                </li>
                <li>
                  <b>General Cancellation:</b> For all other reservation
                  cancellations, a maximum cancellation fee of 14 days will
                  apply.
                </li>
              </ol>
            </p>
            <h3 className="h3">4. Access</h3>
            <p className="p">
              You can check in from 4 pm on your check in date and must check
              out by 11 am on your check out date. Early check-in may be
              arranged beforehand, depending on availability, and will incur a
              one-day accommodation fee. It is required that you provide payment
              in advance to confirm your booking. You must ensure that all
              financial obligations are met prior to accessing the Unit. Keys
              will not be available for access without finalizing financial
              obligations. Sky View Suites retains the authority to complete any
              necessary building-related documentation on your behalf, including
              but not limited to registrations, parcel pickups, and other
              relevant administrative tasks.
            </p>
            <h3 className="h3">5. Term Extensions</h3>
            <p className="p">
              It is required that you leave the unit by 11:00 am local time on
              your check out date, unless Sky View Suites has granted written
              permission for an alternate arrangement. To seek an extension, you
              must submit a written request prior to your notice to vacate
              (located on your booking confirmation). Extension requests are not
              guaranteed and you may be required to vacate the Unit on your
              original check out date.
            </p>
            <h3 className="h3">6. Early Termination</h3>
            <p className="p">
              Although Sky View Suites tries to provide accurate information
              about the neighbourhood/building through photos and descriptions
              on its website, it is important for you to thoroughly research the
              area before making a reservation to ensure you are fully aware of
              the characteristics of the city, and neighbourhood. It should be
              noted that a desire to change neighbourhoods/buildings will not be
              considered a valid reason for early termination of the Agreement.
              The images and descriptions on our website serve as examples, and
              your specific unit may not appear identical to those depicted.
              Requesting a unit change based on these differences does not
              constitute a valid reason for early termination of the Agreement.
              In the event that you wish to shorten the duration of your
              original booking, you must provide written notice to Sky View
              Suites. Charges/refunds will be based on your notice to vacate
              policy as outlined in the Booking Confirmation. Sky View Suites
              retains the right to terminate your reservation at any time, in
              line with the law, if it is discovered that your use of the Unit
              contributes to illegal activities, poses a risk to Sky View
              Suites' reputation, or results from a fraudulent booking or breach
              of the Agreement's terms and conditions.
            </p>
            <h3 className="h3">7. Keys & Premises Access Cards</h3>
            <p className="p">
              You are responsible for the security of the Unit until all keys
              and/or electronic access cards are returned to Sky View Suites.
              You are not allowed to change, rekey the locks, or add additional
              locks or security devices to the Unit. If Sky View Suites staff
              needs to visit the Unit to unlock the door for you or if you lose
              a copy of your keys, you will be charged a service fee of $200. If
              the keys are not returned to Sky View Suites following the
              conclusion or termination of the Agreement, you will be charged an
              additional $200 for direct key replacement costs, locksmith,
              and/or any other costs involved with changing keys/locks.
            </p>
            <h3 className="h3">8. Mail and Parcels</h3>
            <p className="p">
              All mail and parcels intended for delivery to the Unit must be
              directed to the office of Sky View Suites (142 Simcoe St. Toronto
              ON, M5H3G4), where they will be received and held for pick up by
              the recipient. This policy is in place to ensure the timely and
              secure delivery of all mail and packages, as well as to maintain
              the privacy and security of all residents in the building. It is
              the responsibility of the recipient to retrieve their mail and
              parcels from the office during regular business hours, as Sky View
              Suites is unable to deliver mail and parcels directly to
              individual units. Sky View Suites will not be held liable for any
              lost, stolen, or damaged mail or parcels that have been delivered
              to the office and retrieved by you.
            </p>
            <h3 className="h3">9. Parking</h3>
            <p className="p">
              A parking spot may be made available for a fee, upon request and
              subject to availability. However, it is important to note that Sky
              View Suites cannot be held liable for any issues or incidents
              related to parking, such as someone parking in your designated
              spot or any damage or theft to or from a vehicle while parked on
              the property.
            </p>
            <h3 className="h3">10. Use and Occupancy of the Unit</h3>
            <p className="p">
              The Unit is intended for private, residential use only and may not
              be used for any business or commercial purposes. You are not
              permitted to place or store personal belongings outside of the
              Unit, and any unauthorized items will be subject to removal and
              disposal by Sky View Suites or the Property Management without
              notice. During the term, the Unit may only be occupied by you and
              Authorized Occupants. All rules and restrictions outlined in this
              agreement apply to Authorized Occupants. You must not cause any
              waste or nuisance within the Unit or Building, nor take any
              actions that may disrupt the comfort, safety, or enjoyment of
              other Building occupants. Sky View Suites reserves the right to
              regulate the maximum number of occupants allowed in each dwelling
              unit based on its size.
            </p>
            <p className="p">
              <b>Bachelor / Studio and 1 Bedroom Units 2 Occupants</b>
            </p>
            <p className="p">1 Bedroom + Den Units 3 Occupants</p>
            <p className="p">2 Bedroom Units 4 Occupants</p>
            <p className="p">3 Bedroom Units 6 Occupants</p>
            <h3 className="h3">11. Care of Unit</h3>
            <p className="p">
              You are responsible for keeping the Unit, furniture, furnishings,
              and fixtures in good, clean, and habitable condition. You will be
              charged for or required to reimburse Sky View Suites for any
              furniture, furnishings, or fixtures that are broken, lost, stolen,
              or damaged during your stay. You must report any damages to the
              Unit within 48 hours of check in. It is your obligation to report
              any maintenance issues, or other concerns regarding the condition
              of the Unit to Sky View Suites within 48 hours of becoming aware
              of them. You will also be responsible for any general repairs or
              maintenance resulting from damage caused by you, Authorized
              Occupants, or any of your guests. This includes the cost of
              clearing any stoppages in waste pipes, drains, water pipes, or
              plumbing caused by improper use, which you will be required to
              reimburse Sky View Suites. You are not allowed to remodel,
              renovate, paint, refinish floors, or make any other alterations to
              the Unit, common areas, or any other parts of the Building.
              Additionally, you may not remove any of the furniture or
              furnishings from the Unit. If you need to remove furniture, this
              can be accommodated by Sky View Suites at an additional charge,
              but only at their sole discretion and upon specific request.
            </p>
            <h3 className="h3">12. Cleaning</h3>
            <p className="p">
              We arrange and inform you about your cleaning sessions (if
              applicable); note that the ability to reschedule a cleaning is not
              guaranteed and must be requested with at least 24 hours notice. If
              this appointment is cancelled with less than 24 hours notice, the
              cleaning is forfeited and non-refundable. It will not be scheduled
              for a later date to replace this appointment time. Any regularly
              scheduled cleanings do not include linen washing/replacement
              service.
            </p>
            <h3 className="h3">13. Non-Smoking Policy</h3>
            <p className="p">
              The Unit is a non-smoking environment, and smoking of any kind is
              not allowed inside the Unit, on balconies or patios, or in any
              common areas of the Building. If you violate this policy, you will
              be charged a damages fee of $1,000, in addition to the actual
              costs for replacing furniture, additional deep cleaning services,
              repainting, and other necessary remediation services to restore
              the Unit, its furniture, and furnishings to their prior condition.
              You are responsible for informing your guests, invitees, and
              visitors of this smoking prohibition and ensuring they abide by
              it. Any violation of this non-smoking policy will be considered a
              material breach of the Agreement and grounds for termination. If
              smoke is migrating into the Unit from an external source, you must
              promptly notify Sky View Suites in writing. You acknowledge that
              Sky View Suites may also lose up to one month of rental
              availability for the time required to clean, repair, and refurnish
              the Unit due to smoking and agree to be held liable for these
              costs.
            </p>
            <h3 className="h3">14. Pet Policy</h3>
            <p className="p">
              Pet allowance is at the discretion of Sky View Suites and may
              result in additional fees. You will be fully responsible for any
              damages caused by your pet.
            </p>
            <h3 className="h3">15. Notice of Entry</h3>
            <p className="p">
              You acknowledge that Sky View Suites or the Property Management
              has the right to enter the Unit with twenty-four (24) hours’
              notice to inspect, maintain, repair, or replace the furniture and
              furnishings, or for the purpose of complying with any obligations
              imposed by law or without notice in the case of an emergency or
              Property Management.
            </p>
            <h3 className="h3">16. Repairs & Maintenance</h3>
            <p className="p">
              All non-emergency requests, including those submitted outside of
              regular business hours, will be reviewed within 24 hours. Sky View
              Suites will handle all maintenance issues upon request, and there
              may or may not be an additional charge imposed on you, depending
              on the nature of the issue. You acknowledge that the Unit and
              Building may require renovations or repairs from time to time to
              maintain their good condition and that this work may result in
              temporary loss of use of certain Building or Unit amenities, such
              as swimming pools, fitness centers, laundry facilities, etc. You
              agree that any such loss or inconvenience will not justify a
              reduction in accommodation fees.
            </p>
            <h3 className="h3">17. Building Rules and Regulations</h3>
            <p className="p">
              You, your Authorized Occupants, and any guests or visitors must
              always abide by all rules and regulations of the Building.
              Building rules can be obtained upon request. You are responsible
              for ensuring that all guests and visitors also follow these rules,
              regulations, and restrictions. Any failure to comply with the
              Building rules by you, your Authorized Occupants, or guests will
              be considered a significant breach of the Agreement and provides
              the right to Sky View Suites to terminate the Agreement and
              require you to vacate the Unit at its discretion.
            </p>
            <h3 className="h3">18. Emergency Relocation</h3>
            <p className="p">
              There may be instances where a Unit becomes unavailable for all or
              part of the rental period due to factors such as significant
              building maintenance or natural disasters. In such cases, Sky View
              Suites reserves the right to relocate you to a comparable Unit in
              the same Building or a similar quality Building. If you are
              notified of the intended relocation and do not wish to move, or if
              the Unit becomes unavailable during the rental period, you have
              the right to cancel the Agreement and receive a pro-rated refund
              of the remaining Accommodation Fees.
            </p>
            <h3 className="h3">19. Personal Property</h3>
            <p className="p">
              Sky View Suites is not liable for any damage or loss of your
              personal property during your stay or after you have left the
              Unit, including but not limited to fire, water, theft, flooding,
              or similar incidents. Sky View Suites will not be held liable for
              any packages that are lost or stolen. We reserve the right to
              dispose of any personal belongings left in the Unit following your
              check out date and time.
            </p>
            <h3 className="h3">
              20. No Assignment, Subleasing, or Short-Term Rentals
            </h3>
            <p className="p">
              You are not allowed to assign your rights under this Agreement,
              sublet or transfer possession of the Unit to any third-party, or
              list the Unit on any short-term rental site such as Airbnb, VRBO,
              or similar services. Any advertising or online postings, as well
              as actual rentals of the Unit to vacation or short-term guests by
              you, will be considered a material breach of this Agreement and,
              in accordance with applicable law, Sky View Suites may terminate
              the Agreement and require you to vacate the Unit. Anyone who is
              not you or an Authorized Occupant, and who occupies any part of
              the Unit for any period of time for any consideration, including
              payment or bartering of other goods, services, or property
              occupancy rights, will be considered an unauthorized subletting or
              assignment and will be in violation of this section.
            </p>
            <h3 className="h3">21. Included Services</h3>
            <p className="p">
              Unit access, hydro service, and internet service are included in
              the Accommodation Fee as part of the Unit. Anything beyond these
              basic amenities will incur additional fees.
            </p>
            <h3 className="h3">22. Use of Facilities at Own Risk</h3>
            <p className="p">
              In cases where facilities or amenities such as swimming pools,
              fitness equipment, etc. are available, their use is strictly at
              your own risk and at your own discretion and must be in accordance
              with the Building’s rules and regulations.
            </p>
            <h3 className="h3">23. No Criminal Activity</h3>
            <p className="p">
              You must only use the Unit as a residential dwelling and no
              illegal activities of any kind will be tolerated. If you or your
              visitors engage in any illegal activities, this will be considered
              a material breach of the agreement, and Sky View Suites has the
              right to immediately end the agreement.
            </p>
            <h3 className="h3">24. Privacy Policy</h3>
            <p className="p">
              You acknowledge that you have read and agree to the terms and
              conditions in Sky View Suites'{" "}
              <a href="https://www.torontofurnishedrentals.com/privacy-policy/">
                Privacy Policy
              </a>
              , which is incorporated into this Agreement by reference.
            </p>
            <h3 className="h3">25. Force Majeure</h3>
            <p className="p">
              You acknowledge that you have read and agree to the terms and
              conditions in Sky View Suites’ Privacy Policy, which is
              incorporated into this Agreement by reference. Sky View Suites
              does not accept liability or pay any compensation for any
              unforeseen events that may take place in the Unit or Building
              outside of its control. Such events will be considered "Force
              Majeure" events and not as negligence, including but not limited
              to: war or threat of war, riot, civil strife, actual or threatened
              terrorist activity, industrial dispute, natural or nuclear
              disaster, adverse weather conditions, fire, swimming pool closure,
              power outages, air-conditioning failure, elevator shut downs, TV,
              telephone, or internet service interruptions, water shortages,
              construction noise, evacuation by authorities, global pandemics,
              and all similar events. Any delays in Sky View Suites' performance
              of its obligations under this Agreement shall be excused to the
              extent caused by Force Majeure events, and any time periods for
              performance will be extended accordingly.
            </p>
            <h3 className="h3">
              26. Attorneys’ Fees, Collection Agency Fees, Costs, and Expenses
            </h3>
            <p className="p">
              In the event of a violation of this Agreement by you or a breach
              of applicable laws, you agree to pay Sky View Suites for any
              expenses incurred in enforcing its rights, including but not
              limited to collection of owed amounts, obtaining possession of the
              Unit from you, and legal fees. This includes reasonable attorney
              fees, collection agency costs (including their fees and legal
              fees), and all costs of collection and enforcement of any
              resulting judgment against you, as well as any post-judgment costs
              and attorney fees allowed by law.
            </p>
            <h3 className="h3">27. Consent To Electronic Service of Process</h3>
            <p className="p">
              You agree and give your consent for service of any process,
              including formal legal process in any legal proceedings, to be
              done through electronic service, such as email, and any other
              forms of electronic communication, to any contact point you
              provide to Sky View Suites during the booking process. You agree
              to consider electronic service as equivalent to personal service.
              This clause will remain valid even after the termination of this
              agreement.
            </p>
            <h3 className="h3">28. Arbitration Notice for Users</h3>
            <p className="p">
              You agree that, except for certain types of disputes, any disputes
              arising from this Agreement will be resolved through binding
              individual arbitration. By accepting this Agreement, both you and
              Sky View Suites are waiving your rights to a trial by jury and to
              participate in any class action or representative proceedings. You
              also agree to give up your right to bring any claims to court,
              except for matters that are exempt, subject to local laws. Your
              rights will be determined by a neutral arbitrator, not a judge or
              jury.
            </p>
            <h3 className="h3">29. Unauthorized Occupants</h3>
            <p className="p">
              The presence of unauthorized occupants in any of Sky View Suites’
              properties is strictly prohibited and may result in the immediate
              termination of this agreement and/or the removal of all
              unauthorized occupants at Sky View Suites' discretion.
              Unauthorized occupants include, but are not limited to:
            </p>
            <ul>
              <li>
                <strong>Unregistered Guests:</strong>
                <ul>
                  <li>
                    Any individual not listed on the reservation details or
                    guest list.
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  Extended Stay Beyond Checkout Time: Non-Checkout
                </strong>
                <ul>
                  <li>
                    Any guest who remains on the property past the agreed-upon
                    checkout time without prior approval.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Damage to Property:</strong>
                <ul>
                  <li>
                    Any guest who causes damage to the property, including but
                    not limited to furniture, fixtures, appliances, and the
                    structure itself.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Smoking on Premises:</strong>
                <ul>
                  <li>
                    Any individual who smokes inside the property, including
                    cigarettes, cigars, e-cigarettes, or any other smoking
                    devices, in violation of the property's non-smoking policy.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Engagement in Illegal Activities:</strong>
                <ul>
                  <li>
                    Any occupant involved in illegal activities, including but
                    not limited to drug use, distribution, or any criminal
                    behavior.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Violation of House and Building Rules:</strong>
                <ul>
                  <li>
                    Any individual who violates the house or building rules,
                    including noise complaints, disruptive behavior, or any
                    actions that disturb the peace and comfort of their
                    neighbors.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Failure to Pay:</strong>
                <ul>
                  <li>
                    Any guest who fails to pay for their stay as agreed upon in
                    the reservation details.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Assignment, Subleasing, and Short-Term Rentals:</strong>
                <ul>
                  <li>
                    Any guest who assigns, subleases, or rents the property on a
                    short-term basis.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Breach of Terms of Service:</strong>
                <ul>
                  <li>
                    Any guest who breaches any of the terms outlined in the
                    Terms of Service (TOS) agreement.
                  </li>
                </ul>
              </li>
            </ul>
            <h3 className="h3">30. Disclaimer</h3>
            <p className="p">
              Sky View Suites will not be held liable for any claims arising
              from circumstances caused by the fault of the individual(s)
              involved or any members of their party or by the fault of a third
              party not affiliated with Sky View Suites and which could not have
              been foreseen or prevented, or by an event or situation which,
              even after exercising reasonable care, could not have been
              foreseen or prevented. We reserve the right to cancel any booking
              at our discretion prior to check-in without incurring any
              liability.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingAgreement;
